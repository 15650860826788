import axios from "axios";
import {localStorageGet} from "../../sagas/saga_helper";
import { isEmpty } from "../../Helper/gm";

export default function fetchDog(option) {
    return axios({
        method: "post",
        baseURL: isEmpty(option.test_base_url) ? process.env.REACT_APP_BACKEND_HOST : option.test_base_url,
        url: option.url,
        data: Object.assign({}, option.params, {access_token: localStorageGet('access_token')}),
        headers: {
            "X-CSRF-Token": window.ajaxtoken
        },
    });
}
