let BrandApplyGet = (state = {}, action) => {
  switch (action.type) {
    case "BRANDAPPLY_UPDATE":
      return action.addBy;
    case "BRANDAPPLY_OK":
      return Object.assign({}, state, action.addBy);
    default:
      return state;
  }
};

export default BrandApplyGet;
