import axios from "axios";
import {isEmpty} from "./gm";
export default function fetchDog(option) {
    return axios({
        timeout: 100000,
        baseURL: isEmpty(option.test_base_url) ? process.env.REACT_APP_BACKEND_HOST : option.test_base_url,
        method: "get",
        url: option.url,
        params: Object.assign({}, option.params),
        headers: {
            Accept: 'application/json'
        },
        withCredentials: true
    });
}
