import { isEmpty } from "./gm";

export default function findActiveMenu(con) {
  if (isEmpty(con) || isEmpty(con.target)) {
    return false;
  }
  const current_link = window.location.href;
  const setting_class = isEmpty(con.set_class) ? "active" : con.set_class;
  if (!isEmpty(con.cases)) {
    /* 따로 체크해 주어야 하는 케이스 */
    for (let i = 0, len = con.cases.length; i < len; i++) {
      if (current_link.search(con.cases[i].path) !== -1) {
        if (!document.querySelector(con.cases[i].target)) return false;
        document.querySelector(con.cases[i].target).classList.add(setting_class);
        return false;
      }
    }
  }
  /* 특정 케이스에 해당되지 않을 시 실행 */
  const link_arr = document.querySelectorAll(con.target);
  if (!isEmpty(link_arr)) {
    for (let j = 0, arr_len = link_arr.length; j < arr_len; j++) {
      if (!document.querySelector(`.${link_arr[j].dataset.id}`)) return false;
      /* 현재 확인 중인 링크에서 도메인 부분을 변수에 담기 */
      const except_for = link_arr[j].getAttribute('href').split('/')[2];
      /* 변수의 담은 도메인으로 확인 중인 태그의 path 값을 담기, 없을 경우에는 그냥 link 값임 */
      const compare_with = link_arr[j].getAttribute('href').split(except_for)[1] ? link_arr[j].getAttribute('href').split(except_for)[1] : link_arr[j].getAttribute('href');
      if (current_link.search(compare_with) >= 0) {
        /* a 태그의 href 값이 현재 url 값에 포함되어 있을 경우
        인재풀 페이지의 경우 리스트 페이지의 index 넘버가 따로 url의 부여됨으로 href 값과 달라질 수 있는 경우의 수가 많다 */
        document.querySelector(`.${link_arr[j].dataset.id}`).classList.add(setting_class);
        return false;
      } else {
        if (compare_with.indexOf('?') >= 0 && current_link.search(compare_with.split('?')[0]) >= 0) {
          /* 확인하고 있는 a 태그가 파라미터를 가지고 있고, 파라미터를 제거한 문자열이 현재 url 값에 포함되어 있을 경우 */
          document.querySelector(`.${link_arr[j].dataset.id}`).classList.add(setting_class);
          return false;
        }
      }
    }
  }
}
