import React, {Component} from "react";
import { Switch } from "react-router-dom";
import { PublicRoute } from "react-router-with-props";
import {Profile, ReputationManagement } from "./pages";

import initialState from "./initialState";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import createSagaMiddleware from "redux-saga";
import "../assets/scss/partners.scss";
import Reducer from "./reducer";
import { rootSaga } from "./sagas";


let test_base_url = process.env.REACT_APP_BACKEND_HOST;
const partnersSagaMiddleware = createSagaMiddleware();

const partnersStore = createStore(
  Reducer,
  initialState,
  applyMiddleware(partnersSagaMiddleware, thunk)
);
partnersSagaMiddleware.run(rootSaga);

class Partners extends Component {
  render(){
    /* url 값으로 test 환경 세팅 */
    let current_url = document.location.href;
    if(current_url.indexOf('?test_env=')){
      let test_env = current_url.substring( current_url.indexOf('?test_env='), current_url.length);
      let set_env = test_env.substring(test_env.indexOf('=') + 1, test_env.length);
      if(set_env === "plooto") {
        test_base_url = "https://plooto.jobplanet.co.kr";
      }
      if(set_env === "neptune"){
        test_base_url = "https://neptune.intween.net";
      }
    }
    return (
      <Provider store={partnersStore}>
        <Switch>
          <PublicRoute exact path="/partners/profile/edit" component={Profile}/>
          <PublicRoute exact path="/partners/reputation_management" component={ReputationManagement} base_url={test_base_url}/>
          <PublicRoute exact path="/partners/" component={ReputationManagement}/>
        </Switch>
      </Provider>
    );
  }
}

export default Partners;
