import React, {Component} from "react";
import { PublicRoute } from "react-router-with-props";
import { Switch } from "react-router-dom";
import {Analysis, Category, Online, OtherContents, Keyword} from "./pages";

import initialState from "./initialState";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import createSagaMiddleware from "redux-saga";
import "../assets/scss/alri.scss";
import Reducer from "./reducer";
import { rootSaga } from "./sagas";

const alriSagaMiddleware = createSagaMiddleware();

const alriStore = createStore(
  Reducer,
  initialState,
  applyMiddleware(alriSagaMiddleware, thunk)
);
alriSagaMiddleware.run(rootSaga);

class Alri extends Component {
  render(){
    return (
      <Provider store={alriStore}>
        <Switch>
          <PublicRoute exact path="/alri/other_contents" component={OtherContents} />
          <PublicRoute exact path="/alri/analysis" component={Analysis} />
          <PublicRoute exact path="/alri/category" component={Category} />
          <PublicRoute exact path="/alri/keyword" component={Keyword} />
          <PublicRoute exact path="/alri/online" component={Online} />
          <PublicRoute exact path="/alri/" component={Online} />
          <PublicRoute exact path="/alri" component={Online} />
        </Switch>
      </Provider>
    );
  }
}

export default Alri;
