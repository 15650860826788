import React, {Component} from "react";
import { PublicRoute } from "react-router-with-props";
import { Route, Switch } from "react-router-dom";
import { error503, error500, noPage } from "./index";

import "../assets/scss/alri.scss";

class ErrorPage extends Component {
  render(){
    return (
      <Switch>
        <PublicRoute exact path="/error/503" component={error503} />
        <PublicRoute exact path="/error/500" component={error500} />
        <Route component={noPage} />
      </Switch>
    );
  }
}

export default ErrorPage;
