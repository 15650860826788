const alri = (state= {}, action) => {
  switch (action.type) {
    case "ARLI_INIT":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        [action.name]: action.addBy.data
      });
    case "ARLI_MORE":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        [action.name]: action.addBy.data
      });
    case "ARLI_SPINNER":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        loading: action.addBy
      });
    case "ARLI_OTHER_FILTER":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        other_filter: action.addBy
      });
    default:
      return state;
  }
};

export default alri;
