import { combineReducers } from "redux";
import partners from "./partners";
import BrandApplyGet from "./biz_consulting";
import { modal, loading } from "../../reducer";
/* reducer은 action에 의해서 변경된 state를 받아 어플리케이션의 화면 또는 상태가 어떻게 변화할 것인지를 정의함 */
/* 리덕스에서 state는 read-only로서 action 객체가 dispatch(보내다) 되어야만 변경될 수 있습니다. */
/* 초기 상태 정의 */

const Reducer = combineReducers({
  modal, loading, partners, BrandApplyGet
});

export default Reducer;
