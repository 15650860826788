import React, { Component } from "react";
import { isEmpty } from "../Helper/gm";

import findActiveMenu from "../Helper/findActiveMenu";

class B2bGnbCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gnb_menu: this.props.gnb_menu,
      active_menu: "",
      parent_category: "",
      check_cases: {
        target: '.b2b_category .link_group li a',
        set_class: "active"
      },
    };
  }
  componentDidMount() {
    document.addEventListener("click", this.closeMenuGnbList.bind(this), false);
    /* gnb active */
    findActiveMenu(this.state.check_cases);
  }
  myMainMenuAction(target) {
    this.setState({
      active_menu: target,
    });
  }
  closeMenuGnbList() {
    this.myMainMenuAction("");
  }
  renderGnbList(list, depth) {
    return list.map((v, index) => {
      return (
        <li
          key={index}
          className={`ct_dp${depth} ${v.code} ${
            this.state.active_menu === v.text ? "show" : ""
          }`}
        >
          <a
            className="ct_link"
            href={v.link}
            onFocus={() => {
              this.myMainMenuAction(v.text);
            }}
            onBlur={() => {
              if (v.children.length === 0) {
                this.closeMenuGnbList();
              }
            }}
          >
            {v.text}
            {v.level === "new" ? <span className="label_new" /> : ""}
          </a>
          {v.children.length > 0 ? (
            <div className="jply_linkpanel ct_dp2" tabIndex="-1">
              <ul className="link_group">
                {this.renderSecondDepth(v.children, depth + 1, index, v.code)}
              </ul>
            </div>
          ) : (
            ""
          )}
        </li>
      );
    });
  }
  renderSecondDepth(list, depth, mindex, code) {
    var last_index = list.length - 1;
    return list.map((v, index) => {
      return (
        <li key={mindex + "_" + index}>
          <a
            href={v.link}
            onBlur={() => {
              if (last_index === index) {
                this.closeMenuGnbList();
              }
            }}
            data-id={code}
          >
            {v.text}
            {v.level === "admin" ? (
              <span className="b2b_badge_admin">admin</span>
            ) : (
              ""
            )}
          </a>
        </li>
      );
    });
  }

  render() {
    if (isEmpty(this.state.gnb_menu)) return false;
    return (
      <ul
        className="b2b_category"
        onMouseOver={() => {
          this.props.over_event();
        }}
      >
        {this.renderGnbList(this.state.gnb_menu, 1)}
      </ul>
    );
  }
}

export default B2bGnbCategory;
