import apiPost from "../partners/sagas/axiosPost";
import {isEmpty} from "../Helper/gm";
import getNewAccess from "./get_new_access";

export default async function eventLog(option) {
    try{
      const response = await apiPost(option);
      if(response.data.status === 500) {
        console.log('500 Error');
      }
      if(response.data.status === "success"){
        if(!isEmpty(option.direct)){
          window.location.href = option.direct;
        }
      }
    }catch (error) {
      if(error.response.status === 401){
        await getNewAccess();
        await eventLog(option);
      }
      if(error.message.indexOf("503") > 0){
        window.location.href = `/error/503`;
      }
      if(error.message.indexOf("500") > 0){
        window.location.href = `/error/500`;
      }
    }
}
