import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "react-router-with-props";
import { withCookies } from "react-cookie";
import { storageAvailable } from "./Helper/gm";

import Partners from "./partners/Partners";
import Alri from "./alri/Alri";
import ErrorPages from "./error_pages/ErrorPage";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SplitList: null,
      authed: false,
      call_info: [

      ]
    };
  }

  localStorageSet(name, value) {
    if (storageAvailable("localStorage")) {
      localStorage[name] = value;
    } else {
      console.log("localStorage error");
    }
  }

  localStorageGet(name) {
    if (storageAvailable("localStorage")) {
      return localStorage.getItem(name);
    } else {
      return this.props.value[0];
    }
  }

  loginOk() {
    this.setState({
      authed: true
    });
  }

  initCall() {
    return [
      {
        type: "ARLI_CALL",
        action: "ARLI_INIT",
        name: "business_status",
        option: {
          url: this.props.alri.api_url.business_status
        }
      }
    ];
  }

  componentDidMount() {
    /*if (isEmpty(this.props.alri.business_status)) {
      this.initCall().map(d => {
        return this.props.ActionCall(d);
      });
    }*/
  }

  render() {
    return (
      <div>
        <Switch>
          <PublicRoute path="/alri" component={Alri} />
          <PublicRoute path="/partners" component={Partners} />
          <Route component={ErrorPages} />
        </Switch>
      </div>
    );
  }
}


export default withCookies(Routes);
