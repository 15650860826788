import { call, put } from "redux-saga/effects";
import apiGet from "../Helper/axiosGet";
import { isEmpty } from "../Helper/gm";
import { spinnerLoading, localStorageSet } from "./saga_helper";

export default function* loginCheckSage(action) {
  try {
    /*api콜*/
    let test_set_url = isEmpty(action.option.test_base_url) ? "" : action.option.test_base_url;
    yield call(spinnerLoading, "on");
    const response = yield call(apiGet, { url: "/api/v3/business_center/info/info" , test_base_url: test_set_url});
    yield call(spinnerLoading, "off");

    if (response.data.code === 500) {
      yield call(spinnerLoading, "end");
      console.log("500 Error");
    }
    const { data } = response.data;
    if (isEmpty(data.user)) {
      /* 로그인 하지 않았을 때 */
      localStorageSet("jobplanet_spa", "login_no");
      window.location.href = `${process.env.REACT_APP_BACKEND_HOST}/users/sign_in?redirect_url=${window.location.href}`;
    } else {
      if(data.user.is_permission){
        /* 기업 계정 승인이 완료됐을 때 */
        if(action.option.is_auth_check){
          /* 알리 권한 체크가 필요한 경우 */
          if (data.user.is_alri_auth) {
            localStorageSet("jobplanet_spa", "login_ok");
          } else {
            alert("접근 권한이 없습니다.");
            localStorageSet("jobplanet_spa", "login_out");
            window.location.href = `${process.env.REACT_APP_BACKEND_HOST}`;
          }
        }else{
          /* 알리 권한 체크하지 않아도 될 때 */
          localStorageSet("jobplanet_spa", "login_ok");
          if (data.user.access_token){
            localStorageSet("access_token", data.user.access_token);
          }
          if (data.user.refresh_token){
            localStorageSet("refresh_token", data.user.refresh_token);
          }
          if(!isEmpty(data.user.agree_tos_recruitment_url)){
            /* 채용서비스 약관 동의를 하지 않았을 경우 */
            window.location.href = data.user.agree_tos_recruitment_url;
          }
        }
      }else{
        /* 기업 계정 승인이 대기 중일 때 */
        alert("접근 권한이 없습니다.");
        window.location.href = data.user.permission_denied_landing_url;
      }
    }
    yield put({ type: action.action, addBy: response.data, name: action.name });
  } catch (e) {
    yield call(spinnerLoading, "end");
    if(e.message.indexOf("503") > 0){
      window.location.href = `/error/503`;
    }
    if(e.message.indexOf("500") > 0){
      window.location.href = `/error/500`;
    }
    localStorageSet("jobplanet_spa", "error");
    alert("죄송합니다.\n 서버 및 통신 장애로 잠시 후 접속 바랍니다.");
    window.location.href = `https://www.jobplanet.co.kr`;
  }
}
