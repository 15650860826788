import React, { Component } from 'react';
import { isEmpty } from "../../Helper/gm";
import B2bNav from "../../commons/B2bNavBar";
import { connect } from "react-redux";
import JplySpinner from "../../commons/JplySpinner";

const withSplitting = getComponent => {
  class WithSplitting extends Component {
    state = {
      Splitted: null
    };
    constructor(props) {
      super(props);
      this.host = process.env.REACT_APP_BACKEND_HOST;
      getComponent().then(({ default: Splitted }) => {
        this.setState({
          Splitted
        });
      });
      this.props.ActionCall({
        type: "LOGIN_CHECK",
        action: "PARTNERS_INIT",
        name: "jply_info",
        option: {
          is_auth_check: false,
          test_base_url : isEmpty(this.props.base_url) ? "" : this.props.base_url,
          url: "/api/v3/business_center/info/info"
        }
      });
    }

    render() {
      const { Splitted } = this.state;
      const { jply_info } = this.props.partners;
      if (!Splitted) {
        return null;
      }
     return(
       isEmpty(jply_info.user) ? <JplySpinner id={"page_spinner"} /> : (
        <div id="wrapper" >
          <div id="B2bGnb">
            <B2bNav
              target="gnb"
              other={this.other}
              gnb_menu={this.gnb_menu}
            />
          </div>
          <Splitted {...this.props} />
          <div id="B2bBottom">
            <B2bNav
              target="bottom"
              gnb_menu={this.gnb_menu}
            />
          </div>
        </div> )
      )
    }
  }


  const getRow = state => state;
  const applyDispatchToProps = dispatch => ({
    ActionCall: function(v) {
      return dispatch(v);
    }
  });

  WithSplitting = connect(
    getRow,
    applyDispatchToProps
  )(WithSplitting);


  return WithSplitting;
};

export default withSplitting;
