import 'babel-polyfill';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/alri.scss";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";

import Highcharts from 'highcharts'
import HC_more from 'highcharts/highcharts-more'
HC_more(Highcharts);

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("jply_spa")
);
serviceWorker.unregister();
