import React, { Component } from 'react';
import { isEmpty } from "../Helper/gm";

class G_B2bTotalCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gnb_menu : this.props.gnb_menu,
    };
  }
  renderGnbList(list, depth){
    let show_length = 0;
    if(depth === 1){
      show_length = list.filter( d => d.total_show === false).length;
    }
    return(
      list.map((v, index) => {
        if(!isEmpty(v.total_show) || v.total_show === false)  return "";
        return(
          <li key={index} className={`total_ct_dp${depth}`} style={depth === 1 ? {width: (100 / (list.length-show_length))+"%"} : {}} >
            <a className="ct_link" href={v.link}>{v.text}</a>
            {
              v.level === "admin"? (<span className="b2b_badge_admin">admin</span>): ("")
            }
            {
              v.children.length > 0 ? (
                <ul className="total_ct_list">
                  {
                    this.renderGnbList(v.children, depth+1)
                  }
                </ul>
              ) : ("")
            }
          </li>
        )
      })
    );
  }
  render() {
    if(isEmpty(this.state.gnb_menu)) return false;
    return(
      <div className="b2b_total_category">
        <ul className="b2b_gnb_wrapper">
          {
            this.renderGnbList(this.state.gnb_menu, 1)
          }
        </ul>
      </div>
    )
  }
}

export default G_B2bTotalCategory;
