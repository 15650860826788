const API_PATH = process.env.REACT_APP_API_PATH;
const initialState = {
  partners: {
    jply_info: {},
    api_url:{
      b2b_event_log: `/api/v3/business_center/event_log`,
      advertisement: `/api/v3/business_center/banner`,
      business_status: `${API_PATH}/dash_board/business_status`,
      dashboard_external_summary: `/api/v3/business_center/dash_board/external_summary`,
      dashboard_external_reputation: `/api/v3/business_center/dash_board/external_reputation`,
      dashboard_internal_summary: `/api/v3/business_center/dash_board/internal_summary`,
      dashboard_internal_reputation: `/api/v3/business_center/dash_board/internal_reputation`,
      dashboard_job_posting: `/api/v3/business_center/dash_board/job_postings`,
      dashboard_communication_history: `api/v3/business_center/dash_board/sales_histories`,
    },
    service_modal_open: false,
    biz_consulting : {},
    advertisement: {
      is_error: false,
      is_spinner: true,
      contents :{},
    },
    dashboard_external_summary: [],
    dashboard_external_reputation: {
      is_error: false,
      is_spinner: true,
      contents :{
        page_info: {},
        contents: []
      },
    },
    external_other_reputation: {
      is_error: false,
      is_spinner: true,
      contents :{
        page_info: {},
        contents: []
      },
    },
    dashboard_internal_summary: [],
    dashboard_internal_reputation: {
      is_error: false,
      is_spinner: true,
      contents :{
        page_info: {},
        contents: []
      },
    },
    internal_other_reputation: {
      is_error: false,
      is_spinner: true,
      contents :{
        page_info: {},
        contents: []
      },
    },
    dashboard_job_posting: {
      is_error: false,
      is_spinner: true,
      contents :{},
    },
    dashboard_communication_history: {
      is_error: false,
      is_spinner: true,
      contents: {},
    }
  }
};

export default initialState;
