import axios from "axios";
import { localStorageGet } from "../../sagas/saga_helper";
import { isEmpty } from "../../Helper/gm";

export default function fetchDog(option) {
  return axios({
    timeout: 10000,
    baseURL: isEmpty(option.test_base_url) ? process.env.REACT_APP_BACKEND_HOST : option.test_base_url,
    method: "get",
    url: option.url,
    params: Object.assign({}, option.params, {access_token: localStorageGet('access_token')}),
    headers: {
      access_token: localStorageGet('access_token'),
      Accept: 'application/json'
    },
    withCredentials: true
  });
}
