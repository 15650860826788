import {
  all,
  put,
  call,
  takeLatest,
  takeEvery, select
} from "redux-saga/effects";
import apiGet from "../sagas/axiosGet";
import { isEmpty } from "../../Helper/gm";
import { spinnerLoading, localStorageSet } from "../../sagas/saga_helper";
import loginCheckSage from "../../sagas/login_check_sage";
import brandPost from "../../BrandApply/sagas/axiosPost";
import getNewAccess from "../../sagas/get_new_access";
import eventLog from "../../sagas/event_log";


function* spinnerOn(name, v) {
  yield put({
    type: "PARTNERS_SPINNER",
    name: name,
    addBy: v,
  });
}

function* spinnerReset() {
  yield put({
    type: "SPA_SPINNER",
    addBy: 0
  });
}

/*스피너 동기 처리*/

function* secondCall(d, res) {
  const { key_list, option } = d;

  let new_url = `${option.url}?page=${
    isEmpty(option.page) ? 1 : option.page
  }&tab_type=${isEmpty(option.tab_type) ? 0 : option.page}`;

  key_list.map(
    d => (new_url += `&${d}=${JSON.stringify(res[`default_${d}`])}`)
  );

  const new_option = Object.assign(d, {
    option: {
      url: new_url
    }
  });

  yield call(workerSaga, new_option);
}

function* workerSaga(option) {
  try {
    /*api콜*/
    yield call(spinnerLoading, "on");
    const response = yield call(apiGet, option.option);
    yield call(spinnerLoading, "off");
    if (response.data.code > 299) {
      yield call(spinnerLoading, "end");
      console.log("500 Error");
    }
    if (isEmpty(option.modal_name)) {
      yield put({
        type: option.action,
        addBy: response.data,
        name: option.name
      });
    } else {
      yield put({
        type: option.action,
        addBy: response.data,
        name: option.name,
        top: option.top,
        modal_name: option.modal_name
      });
    }
    if (!isEmpty(option.second_api)) {
      yield call(secondCall, option.second_api, response.data.data);
    }
  } catch (error) {
    yield call(spinnerLoading, "end", error.response);
    if(isEmpty(error.response.status)){
      if (error.response.status === 500) {
        console.log(error.response["status"], option);
        window.location.href = `${process.env.REACT_APP_BACKEND_HOST}`;
      }
    }

    localStorageSet("jobplanet_spa", "error");
    window.location.href = "/error";
  }
}

function* openServiceModal (option){
  yield put({
    type: option.action,
    addBy:  option.data,
    modal_name: option.modal_name,
    name: option.modal_name,
    top: option.top,
  });
  if(!isEmpty(option.event_log)){
    yield eventLog(option.event_log);
  }
}

function* openDetailModal(option){
  try {
    let { loading } = yield select();
    if(loading === 0){
      yield call(spinnerLoading, "on");
    }
    const response = yield call(apiGet, option.option);
    if (response.data.status === "success") {
      yield call(spinnerLoading, "off");
      yield put({
        type: option.action,
        addBy:  {
          data: response.data.data
        },
        modal_name: option.modal_name,
        name: option.modal_name,
        top: option.option.top,
      });
      yield eventLog(option.option.event_log);
    } else {
      alert("오류");
    }
  } catch (error) {
    if(error.response.status === 401){
      /* token이 만료됐을 경우 401 에러 발생 */
      yield getNewAccess();
      yield call(openDetailModal, option);
    }else{
      /* token 에러가 아닐 경우 */
      console.log(error);
      if(error.message.indexOf("500") > 0){
        window.location.href = `/error/500`;
      }
      if(error.message.indexOf("503") > 0){
        window.location.href = `/error/503`;
      }
    }
  }
}

function* closeServiceModal (option){
  yield put({
    type: option.action,
    addBy: option.data,
    name: option.name,
  })
}

function* serviceApply(option){
  try {
    let { loading } = yield select();
    if(loading === 0){
      yield call(spinnerLoading, "on");
    }
    const response = yield call(brandPost, option.option);
    if (response.data.status === "success") {
      yield call(spinnerLoading, "off");
      /*임시조치 unmountComponentAtNode()? */
      if (option.option.nextpage === true) {
        /*이메일 방식으로 될 때 살림 트리거 실행은 brandapplysuccesspage 에 있음.*/
        /* 다음 모달 페이지를 노출시키기 위해 모달 재 호출*/
        yield put({
          type: "MODALL_OPEN",
          addBy: {
            data: {
              modal: "true",
              brand_success: "true",
            }
          },
          modal_name: "service_modal_open",
          name: "service_modal_open",
          top:  window.scrollY || window.pageYOffset,
        });
      }
    } else {
      alert("오류");
    }
  } catch (error) {
    if(error.response.status === 401){
      /* token이 만료됐을 경우 401 에러 발생 */
      yield getNewAccess();
      yield call(serviceApply, option);
    }else{
      /* token 에러가 아닐 경우 */
      console.log(error);
      if(error.message.indexOf("500") > 0){
        window.location.href = `/error/500`;
      }
      if(error.message.indexOf("503") > 0){
        window.location.href = `/error/503`;
      }
    }
  }
}

/* partners section 별 api call */
function* partnersSectionApiGet(option){
  try{
    /* 어떤 섹션에 spinner를 열 것인가, 전체 스피너(total)를 켤 것인지 섹션 스피너(section)를 켤 것인지 선택 가능 */
    if(!isEmpty(option.option.spinner)){
      option.option.spinner === "total" ?  yield call(spinnerLoading, "on") :  yield call(spinnerOn, option.name, true);
    }
    const response = yield call(apiGet, option.option);
    if(!isEmpty(option.option.spinner) && option.option.spinner === "total"){
      yield call(spinnerLoading, "off");
    }
    if (response.data.status === "success") {
      yield put({
        type: option.action,
        addBy: Object.assign({}, { is_error: false, is_spinner: false}, {contents: response.data.data}),
        name: option.name
      });
    }else{
      yield put({
        type: option.action,
        addBy: Object.assign({}, { is_error: true, is_spinner: false}, {contents: []}),
        name: option.name
      });
    }
  }catch(error){
    if(error.response.status === 401){
      /* 토큰 만료 시 새로운 토큰 get */
      yield getNewAccess();
      yield call(partnersSectionApiGet, option);
    }
    if(error.response.status === 404){
      /* api가 없는 경우 예외처리 */
      yield put({
        type: option.action,
        addBy: Object.assign({}, { is_error: true, is_spinner: false}, {contents: []}),
        name: option.name
      });
    }
    if(error.message.indexOf("500") > 0){
      window.location.href = `/error/500`;
    }
    if(error.message.indexOf("503") > 0){
      window.location.href = `/error/503`;
    }
  }
}

/* 작업 테스트용 토큰 만료 */
function* expireToken(option){
  try{
    yield call(apiGet, option.option);
  }catch(error){
    console.log('expireToken', option);
  }
}


/*SPA_SPINNER_RESET 알리, 파트너스 모두 사용 중*/
export function* rootSaga() {
  yield all([
    yield takeEvery("SPA_SPINNER_RESET", spinnerReset),
    yield takeLatest("LOGIN_CHECK", loginCheckSage),
    yield takeEvery("PARTNERS_CALL", workerSaga),
    yield takeEvery("PARTNERS_GET", partnersSectionApiGet),
    yield takeEvery("SPA_CALL", workerSaga),
    yield takeEvery("EXPIRE_TOKEN", expireToken),
    yield takeLatest("SHOW_SERVICE_MODAL", openServiceModal),
    yield takeLatest("SHOW_DETAIL_MODAL", openDetailModal),
    yield takeLatest("CLOSE_SERVICE_MODAL", closeServiceModal),
    yield takeLatest("BRANDAPPLY_TO_SUBMIT", serviceApply),
  ]);
}
