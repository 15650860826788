import React, { Component } from "react";
import update from "immutability-helper";
import { isEmpty } from "../Helper/gm";
import { connect } from "react-redux";
import B2bTotalCategory from "./B2bTotalCategory";
import B2bGnbCategory from "./B2bGnbCategory";
import B2bNotification from "./B2bGnbNotification";

class B2bNavBar extends Component {
  constructor(props) {
    super(props);
    this.host = process.env.REACT_APP_BACKEND_HOST;
    this.state = {
      total_menu: false,
      my_info_menu: false,
      my_noti_list: false
    };
  }
  myMenuAction(d) {
    this.setState(
      update(this.state, {
        my_info_menu: { $set: d }
      })
    );
  }
  myNotiAction = plug => {
    this.setState(
      update(this.state, {
        my_noti_list: { $set: plug }
      })
    );
  };

  alriButton(d, i) {
    const { is_alri_auth } = this.props.partners.jply_info.user;
    return is_alri_auth === false ? (
      <li key={i}>
        <a
          href={d.link}
          className="alri"
          id={"spaNaviAlri"}
          type="button"
        >
          <img src={d.img} alt={d.text} />
        </a>
      </li>
    ) : (
      <li key={i}>
        <a
          href={d.link}
          className="alri"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={d.img} alt={d.text} />
        </a>
      </li>
    );
  }
  otherMenuRender(list) {
    if (isEmpty(list) || list.length < 1) return false;
    return (
      <ul className="other_link">
        {list.map((d, i) => {
          return d.text === "alri" ? (
            this.alriButton(d, i)
          ) : (
            <li key={i}>
              <a href={d.link} target="_blank" rel="noopener noreferrer">
                <img src={d.img} alt={d.text} />
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
  totalClose() {
    if (this.state.total_menu === true) {
      this.setState(
        update(this.state, {
          total_menu: { $set: false }
        })
      );
    }
  }
  closeMenu() {
    if (this.state.my_info_menu === true) {
      this.myMenuAction(false);
    }
  }
  initCall() {
    return [
      {
        type: "PARTNERS_CALL",
        action: "PARTNERS_INIT",
        name: "business_status",
        option: {
          url: this.props.partners.api_url.business_status
        }
      }
    ];
  }
  componentDidMount() {
    if (
      isEmpty(this.props.partners.business_status) &&
      this.props.target === "gnb"
    ) {
      this.initCall().map(d => {
        return this.props.ActionCall(d);
      });
    }
    document.addEventListener("click", this.closeMenu.bind(this), false);
  }
  render() {
    const { business_status, jply_info } = this.props.partners;
    if (isEmpty(business_status) || isEmpty(jply_info)) return "";
    return (
      <div className="b2b_gnb_container">
        {this.props.target === "gnb" ? (
          <div className="b2b_gnb">
            <div
              className="gnb_row gnb_head clearfix"
              onMouseOver={() => {
                this.totalClose();
              }}
            >
              <div className="row_wrap">
                <a href={jply_info.user.root_hef} className="b2b_bi_logo">
                  <img
                    src="https://jpassets.jobplanet.co.kr/production/uploads/material/media/2401/Logo_Business.png"
                    alt="jobplanet"
                  />
                </a>
                <div className={`b2b_moreinfo clearfix`}>
                  <B2bNotification
                    myNotiAction={this.myNotiAction}
                    my_noti_list={this.state.my_noti_list}
                  />
                  <div
                    id="tt"
                    className={`admin_area ${
                      this.state.my_info_menu === true ? "show" : ""
                    }`}
                    tabIndex="-1"
                  >
                    <button
                      className="btn_myinfo"
                      type="button"
                      onMouseOver={() => {
                        this.totalClose();
                        this.myNotiAction();
                      }}
                      onFocus={() => {
                        this.myMenuAction(true);
                      }}
                    >
                      <span className="jp_admin_sslogo">
                        <img
                          src={jply_info.user.company_logo}
                          alt="company log"
                        />
                      </span>
                    </button>
                    <div className="jply_b2b_set">
                      <div className="jply_linkpanel">
                        <ul className="info_group">
                          <li className="power">
                            <span className={jply_info.user.privilege_master_code.toLowerCase()}>
                              {jply_info.user.privilege_master_code}
                            </span>
                          </li>
                          <li className="power_company">{`${jply_info.user.company_name} 관리자`}</li>
                          <li className="power_name">{jply_info.user.name}</li>
                        </ul>
                        <ul className="link_group">
                          <li>
                            <a
                              href={`${this.host}${jply_info.user.my_company_href}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              내 기업 페이지로 이동
                            </a>
                          </li>
                          <li>
                            <a
                              data-method="delete"
                              href={`${this.host}/users/sign_out_spa`}
                              rel="nofollow"
                              onBlur={event => {
                                this.myMenuAction(false);
                              }}
                            >
                              로그아웃
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="link_to_btn">
                    <a href="https://www.jobplanet.co.kr" className="link_btn">
                      잡플래닛홈
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="gnb_row gnb_body">
              <div className="row_wrap">
                <B2bGnbCategory
                  gnb_menu={jply_info.menu.gnb_menu}
                  over_event={() => {
                    this.totalClose();
                    this.myNotiAction();
                  }}
                />
                {this.otherMenuRender(jply_info.menu.other)}
              </div>
            </div>
          </div>
        ) : (
          <div className="gnb_row gnb_foot">
            <B2bTotalCategory gnb_menu={jply_info.menu.gnb_menu} />
            <div className="b2b_copyright">
              <div className="b2b_gnb_wrapper">
                <ul className="foot_policy_menu">
                  <li>
                    <a
                      href={`${this.host}/welcome/terms`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      이용약관
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${this.host}/welcome/privacy`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      개인정보처리방침
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${this.host}/welcome/recruitment_policy`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      채용서비스 약관
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf2vGr1DBwo3CvxL_ohmnonI89XyMU1VzV4mVFcBfyqwKb-Ug/viewform"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      광고 및 제휴문의
                    </a>
                  </li>
                </ul>
                <div className="copy_text">
                  ©Jobplanet. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const getRow = state => state;
const applyDispatchToProps = dispatch => ({
  ActionCall: function(v) {
    return dispatch(v);
  }
});
B2bNavBar = connect(
  getRow,
  applyDispatchToProps
)(B2bNavBar);

export default B2bNavBar;
