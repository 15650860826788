import axios from "axios";
import { localStorageGet } from "../../sagas/saga_helper";
export default function fetchDog(option) {
    return axios({
        method: "post",
        url: option.url,
        data: {
            partners_biz_consulting: Object.assign({}, option.params),
            access_token: localStorageGet("access_token")
        },
        headers: {
            'Content-type': 'application/json'
        },
    });
}
