import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isEmpty } from "../../Helper/gm";
import JplySpinner from "../../commons/JplySpinner";

const withSplitting = getComponent => {
  // 여기서 getComponent 는 () => import('./SplitMe') 의 형태로 함수가 전달되야합니다.
  class WithSplitting extends Component {
    state = {
      Splitted: null
    };

    constructor(props) {
      super(props);
      getComponent().then(({ default: Splitted }) => {
        this.setState({
          Splitted
        });
      });

      /*if (isEmpty(this.props.alri.jply_info)) {
        this.props.ActionCall({
          type: "LOGIN_CHECK",
          action: "ARLI_INIT",
          name: "jply_info",
          option: {
            url: "/api/v3/profile/info"
          }
        });
      }else if(isEmpty(this.props.alri.jply_info.user)){
        this.props.ActionCall({
          type: "LOGIN_CHECK",
          action: "ARLI_INIT",
          name: "jply_info",
          option: {
            url: "/api/v3/profile/info"
          }
        });
      }*/
      this.props.ActionCall({
        type: "LOGIN_CHECK",
        action: "ARLI_INIT",
        name: "jply_info",
        option: {
          is_auth_check: true,
          url: "/api/v3/business_center/info/info"
        }
      });
    }


    render() {
      const { Splitted } = this.state;
      if (!Splitted) {
        return null;
      }
      if (isEmpty(this.props.alri.jply_info)) {
        return null;
      }
      return (
        <div>
          {/*<Splitted {...this.props} />;*/}
          {isEmpty(this.props.alri.jply_info.user) ? <JplySpinner id={"page_spinner"} /> : <Splitted {...this.props} />}
        </div>
      )
    }
  }
  const getRow = state => state;
  const applyDispatchToProps = dispatch => ({
    ActionCall: function(v) {
      return dispatch(v);
    }
  });

  WithSplitting = connect(
    getRow,
    applyDispatchToProps
  )(WithSplitting);

  return withRouter(WithSplitting);
};

export default withSplitting;
