import $ from "jquery";
import { put, select } from "redux-saga/effects";
import { storageAvailable } from "../Helper/gm";

export function report_js_error(data) {
  console.log(data, data.error_response);
  try {
    if (data.error_response) {
      data.error_response = {
        status: data.error_response.status,
        status_text: data.error_response.statusText,
        response_text: data.error_response.responseText.substring(0, 200)
      };
    }

    $.ajax({
      url: `${process.env.REACT_APP_BACKEND_HOST}/common/report_js_error`,
      dataType: "json",
      type: "POST",
      data: data,
      success: function(data) {},
      error: function() {}
    });
  } catch (e) {
    try {
      $.ajax({
        url: `${process.env.REACT_APP_BACKEND_HOST}/common/report_js_error`,
        dataType: "json",
        type: "POST",
        data: {
          error: "report_js_error has error!!",
          error_message: e.message,
          error_name: e.name
        },
        success: function(data) {},
        error: function() {}
      });
    } catch (e) {
      /* report_js_error should never raise error */
    }
  }
}

export function* spinnerLoading(state) {
  let { loading } = yield select();
  /*const param_parse = queryString.parse(location);
  const ref = document.referrer;*/
  switch (state) {
    case "on":
      ++loading;
      break;
    case "off":
      --loading;
      break;
    default:
      loading = 0;
      break;
  }

  if (loading < 0) {
    loading = 0;
    yield put({
      type: "SPA_SPINNER",
      addBy: loading
    });
  } else {
    yield put({
      type: "SPA_SPINNER",
      addBy: loading
    });
  }
}

export function localStorageSet(name, value) {
  if (storageAvailable("localStorage")) {
    localStorage[name] = value;
  } else {
    console.log("localStorage error");
  }
}

export function localStorageGet(name) {
  if (storageAvailable("localStorage")) {
    return localStorage.getItem(name);
  } else {
    console.log("localStorage error");
  }
}
