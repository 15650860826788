const partners = (state= {}, action) => {
  switch (action.type) {
    case "PARTNERS_SPINNER":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        [action.name] : {
          ...state[action.name],
          is_spinner: action.addBy,
        }
      });
    case "PARTNERS_INIT":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        [action.name]: action.addBy.data
      });
    case "PARTNERS_DATA":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        [action.name]: action.addBy
      });
    case "SHOW_BIZ_MODAL":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        [action.name]: action.addBy.is_show,
        biz_consulting: {
          ...state.biz_consulting,
          pdf: action.addBy.pdf_link,
          consulting_status: "permit",
          modal_offset: action.addBy.offset_top,
          partners_biz_consulting: {
            ...state.biz_consulting.partners_biz_consulting,
            alri: action.addBy.alri,
            consuting_apply_on: action.addBy.apply_on,
          }
        },
      });
    case "CLOSE_BIZ_MODAL":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        [action.name]: action.addBy,
      });
    case "GET_NEW_TOKEN":
      return Object.assign({}, state, {
        // 1. 빈객체, 2. 현재 state 값 return, 3. 바꾸고자 하는 값
        jply_info: {
          ...state.jply_info,
          access_token: action.addBy,
        },
      });
    default:
      return state;
  }
};

export default partners;
