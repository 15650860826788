import apiGet from "../Helper/axiosGet";
import {localStorageSet, localStorageGet} from "./saga_helper";

export default async function getNewAccess() {
  try {
    const response =  await apiGet( {
      url: "/api/v3/business_center/info/new_access_token",
      params: {
        refresh_token: localStorageGet("refresh_token"),
      }
    });
    if (response.data.status === 500) {
      console.log("500 Error");
    }
    if (response.data.status === "success"){
      localStorageSet("access_token", response.data.data.access_token);
      localStorageSet("refresh_token", response.data.data.refresh_token);
    }
  } catch (e) {
    if(e.message.indexOf("503") > 0){
      window.location.href = `/error/503`;
    }
    if(e.message.indexOf("500") > 0){
      window.location.href = `/error/500`;
    }
    localStorageSet("jobplanet_spa", "error");
  }
}
